import { ReactElement, useState } from "react"
import { FormattedMessage } from "react-intl"
import Button from "@rio-cloud/rio-uikit/Button"
import { FreightPaymentDetailsDialog } from "./FreightPaymentDetailsDialog"
import { featureToggles } from "../../configuration/featuretoggle/UrlFeatureToggleProvider"

export const FreightPaymentDetailsButton = (): ReactElement => {
    const [showFreightPaymentDetailsDialog, setShowFreightPaymentDetailsDialog] = useState(false)

    if (featureToggles.freightPaymentDetails) {
        return <>
            <Button onClick={() => setShowFreightPaymentDetailsDialog(true)}>
                <span className="rioglyph rioglyph-send"/>
                <FormattedMessage id="freightPaymentDetails.button.label"/>
            </Button>
            <FreightPaymentDetailsDialog
                showDialog={showFreightPaymentDetailsDialog}
                onClose={(): void => setShowFreightPaymentDetailsDialog(false)}
            />
        </>
    } else {
        return <></>
    }
}