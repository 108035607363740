import { ReactElement, useState } from "react"
import { FormattedMessage } from "react-intl"
import CustomState from "@rio-cloud/rio-uikit/CustomState"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import Button, { STYLES_MAP } from "@rio-cloud/rio-uikit/Button"
import { FormProvider, useForm } from "react-hook-form"
import { TransportationReport } from "../../model/transportation-report/transportation-report"
import { usePostTransportationReportMutation } from "../../client/transportation-report/transportationReportApi"
import Notification from "@rio-cloud/rio-uikit/Notification"
import { CopyToClipboard } from "./CopyToClipboard"
import { FreightPaymentDetailsInput } from "./FreightPaymentDetailsInput"
import { SubmitConfirmationDialog } from "./SubmitConfirmationDialog"

interface FreightPaymentDetailsDialogProps {
    showDialog: boolean
    onClose: () => void
}

type FreightPaymentDetailsFormInput = TransportationReport

export const FreightPaymentDetailsDialog = (props: FreightPaymentDetailsDialogProps): ReactElement => {
    const { onClose, showDialog } = props
    const defaultValues = {
        transportDetails: {
            transportMode: {
                type: "ROAD",
            }
        },
        shipments: [
            {}
        ]
    } as FreightPaymentDetailsFormInput

    const [postTransportationReportMutation, postTransportationReportMutationState] = usePostTransportationReportMutation()
    const [showSubmitConfirmationDialog, setShowSubmitConfirmationDialog] = useState(false)

    const formMethods = useForm<FreightPaymentDetailsFormInput>({
        mode: "all",
        defaultValues: defaultValues,
    })

    const handleSubmitConfirmation = () => {
        formMethods.trigger().then(isValid => {
            if (isValid && !isLoading) {
                postTransportationReportMutation(formMethods.getValues())
                    .unwrap()
                    .then(() => {
                        setShowSubmitConfirmationDialog(false)
                    })
                    .catch(() => {
                        Notification.error(
                            <FormattedMessage
                                id={"freightPaymentDetails.dialog.error.message"}
                            />
                        )
                        setShowSubmitConfirmationDialog(false)
                    })
            }
        })
    }

    const { isLoading, isSuccess, isError, data, reset: reportIdReset } = postTransportationReportMutationState
    const showDialogFooter = !isSuccess || isError
    const showSuccessMessage = isSuccess && !isError

    const submitFreightPaymentDetails = () => {
        formMethods.trigger().then(isValid => {
            isValid &&
            setShowSubmitConfirmationDialog(true)
        })
    }

    const cancel = () => {
        formMethods.reset(defaultValues)
        reportIdReset()
        onClose()
    }

    return (
        <Dialog
            bsSize={showSuccessMessage ? Dialog.SIZE_MD : Dialog.SIZE_LG}
            onClose={cancel}
            show={showDialog}
            useOverflow={!showSuccessMessage}
        >
            <FormProvider {...formMethods}>
                <Dialog.Title
                    title={<FormattedMessage id={"freightPaymentDetails.dialog.title"}/>}
                    subtitle={<FormattedMessage id={"freightPaymentDetails.dialog.subtitle"}/>}
                />
                <Dialog.Body>
                    {showSuccessMessage ?
                        <FreightPaymentDetailsSubmitted reportId={data.reportId}/> : <FreightPaymentDetailsInput/>}
                </Dialog.Body>
                {showDialogFooter ?
                    <Dialog.Footer>
                        <div className={"pull-right btn-toolbar"}>
                            <Button onClick={cancel}>
                                <FormattedMessage id={"notification.settings.email.delete.cancel"}/>
                            </Button>
                            <Button bsStyle={STYLES_MAP.PRIMARY} onClick={submitFreightPaymentDetails}>
                                <span className="rioglyph rioglyph-send"/>
                                <FormattedMessage id={"freightPaymentDetails.button.label"}/>
                            </Button>
                            <SubmitConfirmationDialog
                                show={showSubmitConfirmationDialog}
                                disableConfirm={isLoading}
                                onClickConfirm={handleSubmitConfirmation}
                                onClickCancel={() => setShowSubmitConfirmationDialog(false)}/>
                        </div>
                    </Dialog.Footer> : null}
            </FormProvider>
        </Dialog>
    )
}

const FreightPaymentDetailsSubmitted = (props: {
    reportId: string
}) => {
    return <CustomState
        outerClassName="border-none"
        icons={[
            {
                name: "rioglyph-ok-sign",
                color: "text-color-highlight",
                className: "text-size-300pct",
            },
        ]}
        message={
            <div className="display-flex flex-wrap">
                <div className="text-size-h3 text-size-h2-xl text-medium width-500 max-width-100pct text-wrap-balance">
                    <FormattedMessage id={"freightPaymentDetails.dialog.success.title"}/>
                </div>

                <div className="flex-1-1 margin-y-20">
                    <CopyToClipboard text={props.reportId}/>
                </div>
                <div className="alert alert-dismissible alert-warning text-color-warning">
                    <div className="display-flex gap-10">
                        <span className="text-size-h4 rioglyph rioglyph rioglyph-exclamation-sign"/>
                        <div>
                            <div className={"text-left white-space-pre-line"}>
                                <FormattedMessage
                                    id={"freightPaymentDetails.dialog.success.message"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    />
}

