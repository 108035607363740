import { useGetFreightForwarderInfoQuery } from "../../client/freight-forwarder-info/freightForwarderInfoApi"
import { useFormState } from "react-hook-form"
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel"
import { FormattedMessage } from "react-intl"
import { FormTextInput } from "../Form/FormTextInput"
import { FormSelect } from "../Form/FormSelect"
import { meansOfTransport, TransportationReport } from "../../model/transportation-report/transportation-report"
import { FormDatePicker } from "../Form/FormDatePicker"

type TransportDetailsFormInput = Pick<TransportationReport, "transportDetails">

export const TransportDetailsPanel = () => {
    const formState = useFormState<TransportDetailsFormInput>({
        name: "transportDetails",
    })

    const hasErrors = formState.errors.transportDetails !== undefined

    return <ExpanderPanel
        open
        title={<>
            {hasErrors ? <span className={"rioglyph rioglyph-error-sign padding-right-5"}/> : undefined}
            <FormattedMessage id={"freightPaymentDetails.transportData"}/>
        </>
        }
        iconLeft={true}
        bsStyle={"separator"}
        titleClassName={hasErrors ? "text-danger" : ""}
        unmountOnExit={false}
    >
        <FreightForwarderDetails/>
        <LoadingDetails/>
        <UnloadingDetails/>
        <MeansOfTransport/>
    </ExpanderPanel>
}

const FreightForwarderDetails = () => {
    const { data, error, isLoading } = useGetFreightForwarderInfoQuery()

    return <div
        className="margin-bottom-15 align-items-center justify-content-center padding-top-20 padding-bottom-5 padding-x-20 bg-lightest">
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormSelect
                    fieldName={"transportDetails.freightForwarder.duns"}
                    label={<FormattedMessage id={"carrier.duns"}/>}
                    options={(data?.dunsNumbers ?? []).map(duns => ({ id: duns, label: duns, }))}
                    disabled={isLoading || error !== undefined}
                    required={true}
                    validationMessageOverrides={{
                        required: <FormattedMessage id={"freightPaymentDetails.carrier.duns.required"}/>
                    }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.freightForwarder.supplierCode"}
                    label={<FormattedMessage id={"carrier.supplierCode"}/>}
                    required={true}
                    validationMessageOverrides={{
                        required: <FormattedMessage id={"freightPaymentDetails.carrier.supplierCode.required"}/>
                    }}
                />
            </div>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.borderoNumber"}
                    label={<FormattedMessage id={"transportOrder.confirmation.input.label.bordero"}/>}
                    required={true}
                    validationMessageOverrides={{
                        required: <FormattedMessage id={"freightPaymentDetails.borderoNumber.required"}/>
                    }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.accessCode"}
                    label={<FormattedMessage id={"transportOrder.shipment.address.accessCode"}/>}
                    required={false}
                />
            </div>
        </div>
    </div>
}

const LoadingDetails = () => {
    return <div
        className="display-flex gap-20 margin-bottom-15 align-items-center justify-content-center padding-20 bg-lightest">
        <div className="padding-x-20 text-center ">
            <span className="text-size-16 text-color-gray">
                <span className="rioglyph rioglyph-start text-size-200pct">
                </span>
            </span>
        </div>
        <div className={"flex-basis-100pct"}>
            <FormDatePicker<TransportDetailsFormInput>
                fieldName={"transportDetails.loading.loadedAt"}
                label={<FormattedMessage id={"freightPaymentDetails.loading.loadedAt"}/>}
                required={true}
                allowTime={false}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.loading.loadedAt.required"}/>
                }}
            />
        </div>
        <div className={"flex-basis-100pct"}>
            <FormTextInput<TransportDetailsFormInput>
                fieldName={"transportDetails.loading.duns"}
                label={<FormattedMessage id={"freightPaymentDetails.loading.duns"}/>}
                required={true}
                mask={"999999999"}
                minLength={9}
                maxLength={9}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.loading.duns.required"}/>
                }}
            />
        </div>
        <div className={"flex-basis-100pct"}>
            <FormTextInput<TransportDetailsFormInput>
                fieldName={"transportDetails.loading.supplierCode"}
                label={<FormattedMessage id={"freightPaymentDetails.loading.supplierCode"}/>}
                required={true}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.loading.supplierCode.required"}/>
                }}
            />
        </div>
    </div>
}

const UnloadingDetails = () => {
    return <div
        className="display-flex gap-20 margin-bottom-15 align-items-center justify-content-center padding-20 bg-lightest">
        <div className="padding-x-20 text-center ">
            <span className="text-size-16 text-color-gray">
                <span className="rioglyph rioglyph-finish text-size-200pct">
                </span>
            </span>
        </div>
        <div className={"flex-basis-100pct"}>
            <FormDatePicker<TransportDetailsFormInput>
                fieldName={"transportDetails.unloading.unloadedAt"}
                label={<FormattedMessage id={"freightPaymentDetails.unloading.unloadedAt"}/>}
                required={true}
                allowTime={false}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.unloading.unloadedAt.required"}/>
                }}
            />
        </div>
        <div className={"flex-basis-100pct"}>
            <FormTextInput<TransportDetailsFormInput>
                fieldName={"transportDetails.unloading.duns"}
                label={<FormattedMessage id={"freightPaymentDetails.unloading.duns"}/>}
                required={true}
                mask={"999999999"}
                minLength={9}
                maxLength={9}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.unloading.duns.required"}/>
                }}
            />
        </div>
        <div className={"flex-basis-100pct"}>
            <FormTextInput<TransportDetailsFormInput>
                fieldName={"transportDetails.unloading.supplierCode"}
                label={<FormattedMessage id={"freightPaymentDetails.unloading.supplierCode"}/>}
                required={true}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.unloading.supplierCode.required"}/>
                }}
            />
        </div>
    </div>
}

const MeansOfTransport = () => {
    return <div
        className="display-flex gap-20 align-items-center justify-content-center padding-20 bg-lightest">
        <div className="padding-x-20 text-center ">
            <span className="text-size-16 text-color-gray">
                <span className="rioglyph rioglyph-truck text-size-200pct">
                </span>
            </span>
        </div>
        <div className={"flex-basis-100pct"}>
            <FormSelect
                fieldName={"transportDetails.transportMode.meansOfTransport"}
                label={<FormattedMessage id={"meansOfTransport.type"}/>}
                options={meansOfTransport.map(it => ({
                    id: it,
                    label: <FormattedMessage id={`meansOfTransport.type.${it}`}/>
                }))}
                required={true}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.meansOfTransport.type.required"}/>
                }}
            />
        </div>
        <div className={"flex-basis-100pct"}>
            <FormTextInput<TransportDetailsFormInput>
                fieldName={"transportDetails.transportMode.licensePlate"}
                label={<FormattedMessage id={"licensePlate"}/>}
                required={true}
                validationMessageOverrides={{
                    required: <FormattedMessage id={"freightPaymentDetails.licensePlate.required"}/>
                }}
            />
        </div>
    </div>
}

