import { useFieldArray, useFormState } from "react-hook-form"
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel"
import { FormattedMessage } from "react-intl"
import { FormTextInput } from "../Form/FormTextInput"
import { Shipment, TransportationReport } from "../../model/transportation-report/transportation-report"
import Button from "@rio-cloud/rio-uikit/Button"

type ShipmentsFormInput = Pick<TransportationReport, "shipments">

const ShipmentPanel = (props: { shipmentIndex: number; removeShipment: (() => void) | undefined }) => {
    const { removeShipment, shipmentIndex } = props
    const formState = useFormState({ name: `shipments.${shipmentIndex}` })
    const hasErrors = formState.errors.shipments !== undefined && formState.errors.shipments[shipmentIndex] !== undefined

    return <ExpanderPanel
        open
        title={<>
            {hasErrors ? <span className={"rioglyph rioglyph-error-sign padding-right-5"}/> : undefined}
            <FormattedMessage id={"shipment.numbered"} values={{ num: shipmentIndex + 1 }}/>
            {removeShipment !== undefined && <span className={"padding-left-10"}>
                <span
                    className={"rioglyph rioglyph-trash text-color-gray cursor-pointer"}
                    data-testid={`remove-shipment-${shipmentIndex}`}
                    onClick={event => {
                        event.stopPropagation() // Stop expander from collapsing
                        removeShipment()
                    }}/>
            </span>}
        </>}
        iconLeft={true}
        bsStyle={"separator"}
        titleClassName={hasErrors ? "text-danger" : ""}
        unmountOnExit={false}
    >
        <div className={"bg-lightest padding-20"}>
            <div className={"display-flex gap-20 margin-bottom-15"}>
                <div className={"flex-basis-100pct"}>
                    <FormTextInput<ShipmentsFormInput>
                        fieldName={`shipments.${shipmentIndex}.shipmentNumber`}
                        label={<FormattedMessage id={"shipment.shipmentNumber"}/>}
                        required={true}
                        validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipmentNumber.required"}/> }}
                    />
                </div>
                <div className={"flex-basis-100pct"}/>
            </div>
            <div className={"display-flex gap-20 margin-bottom-15"}>
                <div className={"flex-basis-100pct"}>
                    <FormTextInput<ShipmentsFormInput>
                        fieldName={`shipments.${shipmentIndex}.shipFrom.duns`}
                        label={<FormattedMessage id={"shipFrom.duns"}/>}
                        required={true}
                        mask={"999999999"}
                        minLength={9}
                        maxLength={9}
                        validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipFrom.duns.required"}/> }}
                    />
                </div>
                <div className={"flex-basis-100pct"}>
                    <FormTextInput<ShipmentsFormInput>
                        fieldName={`shipments.${shipmentIndex}.shipFrom.supplierCode`}
                        label={<FormattedMessage id={"shipFrom.supplierCode"}/>}
                        required={true}
                        validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipFrom.supplierCode.required"}/> }}
                    />
                </div>
            </div>
            <div className={"display-flex gap-20 margin-bottom-15"}>
                <div className={"flex-basis-100pct"}>
                    <FormTextInput<ShipmentsFormInput>
                        fieldName={`shipments.${shipmentIndex}.shipTo.duns`}
                        label={<FormattedMessage id={"shipTo.duns"}/>}
                        required={true}
                        mask={"999999999"}
                        minLength={9}
                        maxLength={9}
                        validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipTo.duns.required"}/> }}
                    />
                </div>
                <div className={"flex-basis-100pct"}>
                    <FormTextInput<ShipmentsFormInput>
                        fieldName={`shipments.${shipmentIndex}.shipTo.identifier`}
                        label={<FormattedMessage id={"shipTo.identifier"}/>}
                        required={true}
                        validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipTo.identifier.required"}/> }}
                    />
                </div>
            </div>
        </div>
    </ExpanderPanel>
}

export const ShipmentPanelList = () => {

    const { fields, append, remove } = useFieldArray<ShipmentsFormInput>({
        name: "shipments",
        rules: {
            required: true,
            minLength: 1,
        },
    })

    return <>
        {fields.map((_, index) =>
            <ShipmentPanel
                key={`shipment-${index}-of-${fields.length}`}
                shipmentIndex={index}
                removeShipment={fields.length > 1 ? () => remove(index) : undefined}
            />)}

        <div className={"text-center"}>
            <Button onClick={() => append({ } as Shipment)}>
                <span className={"rioglyph rioglyph-plus-light"}/>
                <FormattedMessage id={"shipment.add"}/>
            </Button>
        </div>
    </>
}
